::-webkit-scrollbar {
	display: none;
}

@font-face {
	font-family: "Black-italic";
	src: url("./assets/fonts/Montserrat-BlackItalic.ttf");
}

@font-face {
	font-family: "Black";
	src: url("./assets/fonts/Montserrat-Black.ttf");
}

@font-face {
	font-family: "Thin";
	src: url("./assets/fonts/Montserrat-Thin.ttf");
}

@font-face {
	font-family: "Thin-italic";
	src: url("./assets/fonts/Montserrat-ThinItalic.ttf");
}

@font-face {
	font-family: "Extra-ligth";
	src: url("./assets/fonts/Montserrat-ExtraLight.ttf");
}

@font-face {
	font-family: "Ligth";
	src: url("./assets/fonts/Montserrat-Light.ttf");
}

@font-face {
	font-family: "Regular";
	src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
	font-family: "Bold";
	src: url("./assets/fonts/Montserrat-Bold.ttf");
}

.text-ligth {
	font-family: "Ligth";
}

.text-bold {
	font-family: "Bold";
}

.text-extra-ligth {
	font-family: "Extra-ligth";
}

.font-black {
	font-family: "Black";
}

.text-regular {
	font-family: "Regular";
}

.content-top {
	padding-top: 48px;
}

hr {
	opacity: 1 !important;
}

a {
	text-decoration: none;
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Regular";
}
